import { defineCommand, defineReceiver } from '@drapejs/invoker';
import { fetchPage } from '@drapejs/core';
import { batch, request, query } from '@motillo/drapejs-litium';
import { gql } from 'graphql-request';

import { getCategoryCacheKey } from '../../src/composables/useProductListing';

const takeProductsCount = 48;

export const commands = {
  loadAllProducts: defineCommand<{
    url: string;
    categorySystemId: string;
    path: string;
    query: any;
    cacheKey: string;
  }>('getCategoryDataRequest'),
};

function isCategoryPage(data: any) {
  return (
    data?.dataJson?.dataJson?.category?.systemId &&
    !data?.dataJson?.dataJson?.product?.systemId
  );
}

export const process = defineReceiver(
  fetchPage,
  async function (command, data) {
    if (!isCategoryPage(data)) {
      return data;
    }

    const categoryCacheKey =  getCategoryCacheKey(command.path, command.query);
    const categoryResult = await this.cache.getItem(categoryCacheKey);
    // resetting cache key since query is nested and drape is not configurable for batched queries
    this.cache.setItem(categoryCacheKey, categoryResult.productSearch);

    data.hits = categoryResult?.productSearch?.hits || 0;
    this.cache.setItem(command.cacheKey, data);
  }
);

export const schedule = defineReceiver(
  fetchPage,
  async function (command, data) {
    if (!isCategoryPage(data)) {
      return data;
    }

    await this.invoke(batch.scheduleQuery, {
      cacheKey: getCategoryCacheKey(command.path, command.query),
      query: gql`products {
      productSearch(categorySystemId: $categorySystemId, skip: $skip, take: $take, facets: $facets, searchPhrase: $searchPhrase, sort: $sort, mapKnownSearchTermsToFacets: $mapKnownSearchTermsToFacets) {
        ${getProductsQueryFields()}}
      }`,
      variables: {
        categorySystemId: {
          value: data?.dataJson?.dataJson?.category?.systemId || '',
          type: 'String',
        },
        skip: {
          value: 0,
          type: 'Int',
        },
        take: {
          value: takeProductsCount,
          type: 'Int',
        },
        facets: {
          value: extractFacetsFromQuery(command),
          type: 'String',
        },
        searchPhrase: {
          value: decodeURIComponent(command.query?.search || ''),
          type: 'String',
        },
        sort: {
          value: command.query.sort || 'relevance',
          type: 'String',
        },
        mapKnownSearchTermsToFacets: {
          value: true,
          type: 'Boolean',
        },
      },
    });
    
    return data;
  }
);

export const receivers = {
  loadAllProducts: defineReceiver(
    commands.loadAllProducts,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            query loadAllProducts($url: String!, $categorySystemId: String, $take: Int, $skip: Int, $facets: String, $sort: String, $searchPhrase: String) {
              session(url: $url) {
                products {
                productSearch(
                  categorySystemId: $categorySystemId,
                  take: $take,
                  skip: $skip,
                  facets: $facets,
                  sort: $sort,
                  searchPhrase: $searchPhrase
                ) {
                  ${getProductsQueryFields()}
                }
              }
              }
            }
          `
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          categorySystemId: command.categorySystemId,
          skip: takeProductsCount,
          take: 999,
          facets: extractFacetsFromQuery(command),
          searchPhrase: decodeURIComponent(command.query?.search || ''),
          sort: command.query.sort || 'relevance',
        }
      );

      const { productSearch } = result.session.products;
      const cacheKey = getCategoryCacheKey(command.path, command.query);

      const categoryResult = await this.cache.getItem(cacheKey);
      
      categoryResult.products = [
        ...(categoryResult?.products ? categoryResult?.products : []),
        ...(productSearch?.products ? productSearch?.products : []),
      ];
      await this.cache.setItem(cacheKey, categoryResult);
      return categoryResult;
    }
  ),
};

function getProductsQueryFields() {
  return `
    take
    hits
    totalHits
    categorySystemId
    searchPhrase   
    facets {
      id
      name
      type
      isActive
      values {
        id
        text
        count
        isActive
      }
      range {
        min
        max
      }
      selectedRange {
        min
        max
      }
      selectedDate
      selectedNumber
      values {
        noFilterAppliedCount
      }
    }
    products {
      articleNumber
      productName
      url
      price
      campaignPrice
      originalPrice
      image
      variants {
        articleNumber: id
          size
          color
        }
      googleAnalyticsName
    }`;
}

function extractFacetsFromQuery(command: any) {
  const facets = JSON.stringify(
    command.query?.facets
      ? decodeURIComponent(command.query.facets)
          .split(',')
          .map((e) => e.split(':'))
          .reduce((acc: any, cur) => {
            acc[cur[0]] = cur[1].split('|');
            return acc;
          }, {})
      : {}
  );
  return facets;
}
