<script>
import TextInput from "../forms/TextInput.vue";
import Datepicker from "../forms/DatePicker";

import format from "date-fns/format";

export default {
  components: {
    TextInput,
    Datepicker,
  },
  props: {
    facet: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:selected"],
  data: () => ({
    date: null,
    amount: "",
    changeQuantityTimeout: null,
  }),
  computed: {
    locale() {
      return this.$channel.locale;
    },
  },
  methods: {
    toggleValue() {
      if (this.changeQuantityTimeout) {
        clearTimeout(this.changeQuantityTimeout);
      }

      this.changeQuantityTimeout = setTimeout(() => {
        this.updateFacet();
      }, 500);
    },
    updateFacet() {
      this.facet.isActive = this.date && this.amount ? true : false;

      if (this.date && this.amount) {
        this.previousAmount = this.amount;
        this.$emit("update:selected", [
          {
            date: format(this.date, "yyyy-MM-dd"),
            number: this.amount,
          },
        ]);
      } else {
        this.$emit("update:selected", []);
      }
    },
    getValueClasses(item) {
      const classes = [];
      if (!item.isActive && !item.count) {
        classes.push("checkbox-facet__list-item--disabled");
      } else if (item.isActive) {
        classes.push("checkbox-facet__list-item--active");
      }
      return classes;
    },
    clear() {
      this.amount = "";
      this.date = new Date();
    },
  },
  created() {
    const selectedDate = new Date(this.facet.selectedDate);
    this.date =
      format(selectedDate, "yyyy-MM-dd") != "0001-01-01"
        ? new Date(this.facet.selectedDate)
        : new Date();

    this.amount = this.facet?.selectedNumber || "";
  },
  mounted() {
    this.$refs.amountInput.focus();
  },
};
</script>

<template>
  <div class="available-stock-facet">
    <text-input
      :label="$globalTexts.facets__range_amount"
      v-model="amount"
      @keyup="toggleValue"
      ref="amountInput"
      type="number"
      min="1"
    />

    <div class="availabe-stock-facet__date-picker">
      <div class="availabe-stock-facet__date-picker-caption">
        {{ $globalTexts.facets__range_date }}
      </div>
      <datepicker
        v-model="date"
        :locale="locale"
        @update:modelValue="toggleValue"
        class="availabe-stock-facet__date-picker-input"
      />
    </div>
  </div>
</template>

<style>
.available-stock-facet {
  padding: 0.5rem 1rem;
  overflow: hidden;
}

.availabe-stock-facet__date-picker {
  position: relative;
  margin-top: 2rem;
}

.availabe-stock-facet__date-picker-input {
  display: none;
}

.availabe-stock-facet__date-picker-caption {
  font-size: 12px;
  color: #000000;
}
</style>
