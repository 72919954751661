<script>
import { OptionsComponent } from '@drapejs/core';
import DropdownSort from '../DropdownSort.vue';

const defaultSortValue = 'popularity';

export default {
  extends: OptionsComponent,
  components: {
    DropdownSort,
  },
  data: () => ({
    sortValue: '',
    showSort: false,
  }),
  watch: {
    routePathAndQuery() {
      this.setSortValueFromUrl();
    },
  },
  emits: ['update:sortValue'],
  computed: {
    routePathAndQuery() {
      return this.$route?.pathName + this.$route?.query;
    },
    sortOptions() {
      return [
        {
          name: this.$globalTexts.category_sorting_popularity,
          value: 'popularity',
        },
        {
          name:
            this.$globalTexts.category_sorting_price_asc
            || `${this.$globalTexts.category_sorting_price} ↑`,
          value: 'priceasc',
        },
        {
          name:
            this.$globalTexts.category_sorting_price_desc
            || `${this.$globalTexts.category_sorting_price} ↓`,
          value: 'pricedesc',
        },
        {
          name: this.$globalTexts.category_sorting_name,
          value: 'nameasc',
        },
      ];
    },
  },
  methods: {
    setSortValueFromUrl() {
      this.sortValue = this.$route?.query?.sort || defaultSortValue;
    },
    updateSortValue(value) {
      if (value !== this.sortValue) {
        this.sortValue = value;
        this.showSort = false;

        this.$emit('update:sortValue', this.sortValue);
      }
    },
  },
  mounted() {
    this.setSortValueFromUrl();
  },
};
</script>

<template>
  <dropdown-sort
    :sort-options="sortOptions"
    :default-sort="sortValue"
    @updateSort="updateSortValue"
  />
</template>
