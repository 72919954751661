<script>
import ModalContainer from '../ModalContainer.vue';
import CheckboxFacet from './CheckboxFacet.vue';
import RangeFacet from './FacetRangeControl.vue';
import AvailableStockFacet from './AvailableStockFacet.vue';
import Checkbox from '../forms/Checkbox.vue';

export default {
  components: {
    ModalContainer,
    CheckboxFacet,
    RangeFacet,
    AvailableStockFacet,
    Checkbox,
  },
  props: {
    facetList: {
      type: Array,
      required: true,
    },
    openSpecificFacet: {
      type: Object,
      default: null,
    },
  },
  inject: ['productDisplayCountCaption'],
  data() {
    return {
      currentMenuLayer: 0,
      facetComponentName: '',
      facetData: {},
      facetCurrentSelected: [],
      useBackButton: false,
    };
  },
  emits: ['close-menu', 'update:selectedFacet', 'update:clearSelectedFacets', 'update:clearSelectedFacet'],
  watch: {
    facetList() {
      this.facetData =
        this.facetList?.find((f) => f.id === this.facetData?.id) || {};
    },
  },
  created() {
    if (this.openSpecificFacet) {
      this.openFacet(this.openSpecificFacet);
      this.useBackButton = true;
      this.currentMenuLayer = -1;
    }
  },
  computed: {
    hasSelectedFacets() {
      if (this.facetData?.id) {
        return this.facetData.isActive;
      }
      return this.facetList.find((f) => f.isActive);
    },
    priceAndCheckboxList() {
      return (
        this.facetList.filter(
          (facet) =>
            facet.type === 'Range' ||
            facet.type === 'Checkbox' ||
            facet?.id === '#AvailableStock'
        ) || []
      );
    },
    booleanList() {
      return this.facetList.filter((facet) => facet.type === 'Boolean') || [];
    },
    isSecondLevelFacet() {
      return this.currentMenuLayer === -1 || this.currentMenuLayer === 1;
    },
    menuTitle() {
      return this.facetData?.name || this.$globalTexts?.category_facets_all;
    },
  },
  methods: {
    navigateForward() {
      this.currentMenuLayer += 1;
    },
    navigateBack() {
      this.currentMenuLayer -= 1;

      if (this.currentMenuLayer === 0) {
        this.useBackButton = false;
      }

      this.facetData = {};

      if (this.currentMenuLayer < 0) this.closeMenu();
    },
    closeMenu() {
      this.$emit('close-menu');
      this.currentMenuLayer = 0;
    },
    openFacet(facet) {
      if(facet.id === "#AvailableStock"){
        this.facetComponentName = `available-stock-facet`;
      }
      else{
        this.facetComponentName = `${facet.type}-facet`;
      }
      this.facetData = facet;
      this.navigateForward();
    },
    enableBackButton() {
      this.useBackButton = true;
    },
    toggleBoolean(bool) {
      this.$emit('update:selectedFacet', bool);
    },
    onFacetUpdate(key, value) {
      const result = { ...key };
      if (Array.isArray(value)) {
        result.selectedValues = value;
      }

      this.$emit('update:selectedFacet', result);
    },
    clearFacets() {
      if (this.currentMenuLayer == 0) {
        this.$emit('update:clearSelectedFacets');
      } else {
        this.$emit('update:clearSelectedFacet', this.facetData?.id);
      }
    },
  },
};
</script>

<template>
  <modal-container
    @close-modal="closeMenu()"
    @navigate-back="navigateBack()"
    position="right"
    :show-exit-button="false"
    :show-back-button="useBackButton"
    :show-left-exit-button="false"
  >
    <div class="facet-menu">
      <div
        class="facet-menu__top-bar"
        :class="{ 'facet-menu__top-bar--pushed': isSecondLevelFacet }"
      >
        <div class="facet-menu__title">{{ menuTitle }}</div>
        <div
          class="facet-menu__clear-facet"
          v-if="hasSelectedFacets"
          @click="clearFacets()"
        >
          <img
            class="facet-menu__clear-filter-icon"
            src="../../static/icons/trash_icon.svg"
          />
          {{ $globalTexts.category__facets__clear }}
        </div>
      </div>
      <div class="facet-menu__content-wrapper">
        <div class="facet-menu__content">
          <div v-show="currentMenuLayer === 0" class="facet-menu__facet-list">
            <div
              v-for="(facet, idx) in priceAndCheckboxList"
              :key="idx"
              @click="
                enableBackButton();
                openFacet(facet);
              "
              class="facet-menu__facet-list-item"
            >
              {{ facet.name }}
              <img
                class="facet-menu__facet-list-icon"
                src="../../static/icons/chevron_right_icon.svg"
              />
            </div>
            <div
              v-for="facet in booleanList"
              :key="facet.name"
              class="facet-menu__facet-list-item"
            >
              <checkbox
                v-model="facet.isActive"
                :text="facet.name"
                @change="toggleBoolean(facet)"
              />
            </div>
          </div>
          <div v-show="isSecondLevelFacet">
            <component
              :is="facetComponentName"
              :facet="facetData"
              @update:selected="onFacetUpdate(facetData, $event)"
            >
            </component>
          </div>
        </div>
      </div>
      <div class="facet-menu__actions">
        <button
          class="primary-button facet-menu__filter-button"
          @click="closeMenu()"
        >
          {{ productDisplayCountCaption }}
        </button>
      </div>
    </div>
  </modal-container>
</template>

<style>
.facet-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.facet-menu__top-bar {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0 1rem 1.25rem;
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
}

.facet-menu__top-bar--pushed {
  padding-left: 3rem;
}

.facet-menu__title {
  font-weight: 800;
  text-align: left;
}

.facet-menu__title::first-letter {
  text-transform: capitalize;
}

.facet-menu__clear-facet {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.59px;
  right: 1rem;
  color: #6d6d8e;
}

.facet-menu__clear-filter-icon {
  margin-right: 0.5rem;
}

.facet-menu__facet-list-item {
  cursor: pointer;
  font-size: 15px;
  display: flex;
  padding: 14px 1.5rem;
  border-bottom: 1px solid #e7e7e7;
}

.facet-menu__content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
}

.facet-menu__content {
  overflow-y: auto;
}

.facet-menu__actions {
  display: flex;
  border-top: 1px solid var(--color-cloud-white);
  padding: 15px;
}

.facet-menu__filter-button {
  width: 100%;
}

.facet-menu__facet-list-icon {
  margin-left: auto;
  height: 16px;
  filter: invert(84%) sepia(73%) saturate(43%) hue-rotate(256deg)
    brightness(112%) contrast(73%);
}

.facet-menu__facet-list-boolean {
  height: 20px;
  width: 20px;
  background: #dcdcdc;
  margin-right: 0.5rem;
  border-radius: 2px;
  padding: 1px 3px;
  cursor: pointer;
}

.facet-menu__facet-list-boolean--enabled {
  background: var(--color-tasty-teal);
}
</style>
