<script>
import ModalContainer from './ModalContainer.vue';

export default {
  components: {
    ModalContainer,
  },
  props: {
    sortOptions: {
      type: Array,
      required: true,
    },
    defaultSort: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showSort: false,
    };
  },
  inject: ['registerModal', 'unregisterModal'],
  emits: ['update-sort'],
  computed: {
    sortValue() {
      return this.defaultSort || 'popularity';
    },
    currentSortName() {
      return (this.sortOptions || []).find((s) => s.value === this.sortValue)?.name;
    },
  },
  methods: {
    toggleSort() {
      this.showSort ? this.closeSort() : this.openSort();
    },
    openSort() {
      this.showSort = true;
      this.registerModal('DropdownSort');
    },
    closeSort() {
      this.unregisterModal('DropdownSort');
      this.showSort = false;
    },
    emitUpdateSort(value) {
      this.$emit('update-sort', value);
      this.closeSort();
    },
  },
};
</script>

<template>
  <div class="dropdown-sort">
    <div class="dropdown-sort__active-option" @click="toggleSort()">
      <div class="dropdown-sort__text">
        {{ currentSortName }}
      </div>
      <img
        :class="{ 'dropdown-sort__chevron--up': showSort }"
        src="../static/icons/chevron_down_icon.svg"
      />
    </div>
    <teleport v-if="showSort" to="body">
      <modal-container @close-modal="closeSort()" position="right">
        <div class="dropdown-sort__option-list">
          <div class="dropdown-sort__option-title">{{$globalTexts.global__sort_by}}</div>
          <div
            class="dropdown-sort__option"
            v-for="option in sortOptions"
            :key="option.value"
            @click="emitUpdateSort(option.value)"
          >
            <img
              class="dropdown-sort__option-checkmark"
              v-if="option.value == sortValue"
              src="../static/icons/checkmark_teal_icon.svg"
            />
            {{ option.name }}
          </div>
        </div>
      </modal-container>
    </teleport>
  </div>
</template>

<style>
.dropdown-sort__active-option {
  color: var(--color-tasty-teal);
  display: flex;
}

.dropdown-sort {
  font-size: 13px;
  cursor: pointer;
}

.dropdown-sort__text {
  margin: 0 0.25rem;
  line-height: 13px;
}

.dropdown-sort__chevron--up {
  transform: rotate(180deg);
}

.dropdown-sort__option-list {
  padding: 1rem 0;
}

.dropdown-sort__option-title {
  font-weight: 800;
  padding: 0 1rem 1rem;
  border-bottom: 1px solid #e7e7e7;
}

.dropdown-sort__option {
  cursor: pointer;
  font-size: 15px;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e7e7e7;
}

.dropdown-sort__option-checkmark {
  margin-right: 0.25rem;
}

.dropdown-sort__option:hover {
  background: #dcdcdc;
}

@media (min-width: 768px) {
  .dropdown-sort {
    font-size: 16px;
  }
}
</style>
