<script>
export default {
  props: {
    targetClass: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    backToTopObserver: null
  }),
  methods: {
    backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },

  mounted() {
    const options = {
      rootMargin: "200px 0px 0px 0px",
    };

    this.backToTopObserver = new IntersectionObserver((entries) => {
      const button = document.querySelector("#back-to-top-button");
 
      entries.forEach((entry) => {
        if (button) {
          if (!entry.isIntersecting && button.style.display === "none") {
            button.style.display = "flex";
          } else {
            button.style.display = "none";
          }
        }
      });
    }, options);

    const target = document.getElementsByClassName(this.targetClass);
    if (target[0]) {
      this.backToTopObserver.observe(target[0]);
    }
  },
  beforeUnmount(){
    this.backToTopObserver?.disconnect();
  }
};
</script>

<template>
  <div id="back-to-top-button" @click="backToTop">
    {{ $globalTexts.global_navigation_backtotop }}
  </div>
</template>

<style>
#back-to-top-button {
  position: fixed;
  align-items: center;
  top: 70px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 50;
  display: none;
  font-weight: 600;
  letter-spacing: 0.59px;
  font-size: 13px;
  padding: 7px 1rem;
  background: var(--color-basic-white);
  color: var(--color-tasty-teal);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), -2px -2px 8px rgba(0, 10, 47, 0.1);
  border-radius: 18px;
  cursor: pointer;
}
</style>
