<script>
import { cloneDeep } from 'lodash';
import AllFacets from '../AllFacets';
import Checkbox from '../forms/Checkbox.vue';

const fullTextSearchFacetType = 'fullTextSearch';

export default {
  components: {
    AllFacets,
    Checkbox,
  },
  data: () => ({
    showFacetMenu: false,
    searchValue: '',
    selectedFacets: null,
    openSpecificFacet: null,
    scrollWith: 500,
    leftObserver: null,
    rightObserver: null,
    canScrollLeft: false,
    canScrollRight: false,
  }),
  inject: ['registerModal', 'unregisterModal', 'productData'],
  emits: ['update:facets'],
  watch: {
    routePathAndQuery() {
      this.setSelectedFacetsAndSearchFromUrl();
    },
  },
  computed: {
    routePathAndQuery() {
      return this.$route?.query;
    },
    productFacets() {
      if (!this.productData?.facets) return [];

      const facets = [];
      this.productData.facets.forEach((f) => {
        const facet = cloneDeep(f);
        switch (facet.id) {
          case '#Category':
            facet.name = this.$globalTexts.category_facets_category;
            break;
          case '#Price':
            facet.name = this.$globalTexts.category_facets_price;
            facet.rangeFormat = (value) => this.$formatPrice(value);
            break;
          case '#AvailableStock':
            facet.name = this.$globalTexts.category_facets_available_stock;
            break;
          default:
            break;
        }

        if (facet.type === 'Checkbox') {
          if (
            (facet.values || []).reduce(
              (acc, curr) => (acc += curr.count),
              0,
            ) === 0
          ) {
            facet.isDisabled = true;
          }

          if (
            facet.values?.length > 0
            || (facet.values || []).reduce(
              (acc, curr) => (acc += curr.noFilterAppliedCount),
              0,
            ) > 0
          ) {
            facets.push(facet);
          }
        } else {
          facets.push(facet);
        }
      });

      return facets;
    },
    activefacets() {
      const activeFacets = [];

      this.productFacets.forEach((productFacet) => {
        if (productFacet.isActive || this.selectedFacets?.[productFacet.id]) {
          activeFacets.push({
            id: productFacet.id,
            type: productFacet.type,
            values: this.getActiveFacetValues(productFacet),
          });
        }
      });

      const searchPhrase = this.productData?.searchPhrase?.trim() || '';
      if (searchPhrase && this.searchValue) {
        activeFacets.unshift({
          id: fullTextSearchFacetType,
          values: [
            {
              text: `"${searchPhrase}"`,
              isActive: true,
            },
          ],
        });
      }

      return activeFacets;
    },
    facetsUrl() {
      return Object.keys(this.selectedFacets)
        .reduce((r, c) => {
          const values = this.selectedFacets[c];

          if (typeof values === 'boolean') {
            return r.push(`${c}:${values}`) && r;
          }

          if (!Array.isArray(values)) {
            return r;
          }

          if (
            values.length === 1
            && Object.prototype.hasOwnProperty.call(values[0], 'min')
            && Object.prototype.hasOwnProperty.call(values[0], 'max')
          ) {
            return r.push(`${c}:[${values[0].min}-${values[0].max}]`) && r;
          }

          if (
            values.length === 1
            && Object.prototype.hasOwnProperty.call(values[0], 'date')
            && Object.prototype.hasOwnProperty.call(values[0], 'number')
          ) {
            return r.push(`${c}:[${values[0].date}-${values[0].number}]`) && r;
          }

          return r.push(`${c}:${values.map((v) => v.replaceAll(',', '::')).join('|')}`) && r;
        }, [])
        .join(',');
    },
    productSearhPhrase() {
      return this.productData?.searchPhrase || '';
    },
    hasSelectedFacets() {
      return this.productFacets.find((f) => f.isActive);
    },
  },
  methods: {
    setSelectedFacetsAndSearchFromUrl() {
      if (!this.selectedFacets) {
        this.selectedFacets = this.selectedFacetsFromUrl();
      }

      this.searchValue = this.$route?.query?.search || '';
    },
    isFacetActive(facet) {
      return facet.isActive || this.selectedFacets?.[facet.id];
    },
    clearSelectedFacet(facetId) {
      const facet = this.productFacets.find((f) => f.id === facetId);

      if (facet?.type === 'Checkbox') {
        facet.values.forEach((value) => {
          this.searchValue = this.searchValue?.replace(value.id, '');
        });
      } else if (facet?.type === 'Range') {
        facet.selectedRange = {
          min: facet.range?.min,
          max: facet.range?.max,
        };
      }

      this.updateSelectedFacet(facet);
    },
    clearSelectedFacetValue(facetId, facetValueId) {
      if (facetId === fullTextSearchFacetType) {
        const searchPhrase = this.productSearhPhrase?.trim() || '';
        this.searchValue = this.searchValue.replace(searchPhrase, '');
        this.applyFacets();
      } else {
        const facet = this.productFacets.find((f) => f.id === facetId);
        this.searchValue = this.searchValue
          ?.toUpperCase()
          ?.replace(facetValueId?.toUpperCase(), '');

        if (facet.type === 'Checkbox') {
          facet.selectedValues = facet.values
            .filter((f) => f.isActive && f.id !== facetValueId)
            .map((f) => f.id);
        }

        if (facet.type === 'Boolean') {
          facet.isActive = false;
        }

        this.updateSelectedFacet(facet);
      }
    },
    clearSelectedFacets() {
      this.selectedFacets = [];
      this.searchValue = '';

      this.applyFacets();
    },
    updateSelectedFacet(facet) {
      const facets = this.selectedFacetsFromUrl();
      delete facets[facet.id];

      if (facet.type === 'Boolean' && facet.isActive) {
        facets[facet.id] = facet.isActive;
      } else if (
        Array.isArray(facet.selectedValues)
        && facet.selectedValues.length > 0
      ) {
        facets[facet.id] = facet.selectedValues;
      }

      this.selectedFacets = facets;
      this.applyFacets();
    },
    selectedFacetsFromUrl() {
      const query = decodeURIComponent(this.$route.query.facets);
      const result = {};
      if (!query) return result;

      const facets = query.split(',').filter((f) => !!f).map((f) => f.replaceAll('::', ','));

      facets.forEach((facet) => {
        const f = facet.split(':');
        const value = f[1];

        const rangeFilterMatch = value?.match(/\[(.+?)-(.+?)\]/);
        if (rangeFilterMatch) {
          result[f[0]] = [
            { min: rangeFilterMatch[1], max: rangeFilterMatch[2] },
          ];
        } else {
          result[f[0]] = value?.split('|').filter((r) => !!r);
        }
      });

      return result;
    },
    openFacetMenu() {
      this.showFacetMenu = true;
      this.registerModal('EmbeddedFacets');
    },
    closeFacetMenu() {
      this.unregisterModal('EmbeddedFacets');
      this.showFacetMenu = false;
      this.openSpecificFacet = null;
    },
    openSpecificFacetMenu(facet) {
      if (facet.isDisabled) {
        return;
      }

      if (facet.type === 'Boolean') {
      } else {
        this.openSpecificFacet = facet;
        this.openFacetMenu();
      }
    },
    getActiveFacetValues(facet) {
      if (facet.type === 'Boolean') {
        return [
          {
            id: facet.id,
            text: facet.name,
          },
        ];
      }
      if (facet.id === '#Price') {
        const range = this.selectedFacets?.[facet.id]?.[0] || facet.selectedRange;
        return [
          {
            id: facet.id,
            text: `${range.min}-${this.$formatPrice(range.max)}`,
          },
        ];
      }
      if (facet.id === '#AvailableStock') {
        return [
          {
            id: facet.id,
            text: this.$replaceTokens(
              this.$globalTexts.facets__available_stock,
              {
                count: facet.selectedNumber,
                date: facet?.selectedDate ? this.$formatDate(new Date(facet.selectedDate), 'MMM dd') : '',
              },
            ),
          },
        ];
      }
      return (
        facet.values.filter(
          (f) => f.isActive
            || this.selectedFacets?.[facet.id]?.find((s) => s === f.value),
        ) || []
      );
    },
    applyFacets() {
      const args = {
        facetsUrl: this.facetsUrl,
        searchValue: this.searchValue,
      };

      this.$emit('update:facets', args);
    },
    scrollLeft(ref) {
      const item = this.$refs[ref];
      item.scrollBy(-this.scrollWith, 0);
    },
    scrollRight(ref) {
      const item = this.$refs[ref];
      item.scrollBy(this.scrollWith, 0);
    },
    initAllFacetsArrows() {
      const root = document.querySelector('.allFacets');
      if (root.scrollWidth <= root.clientWidth) {
        return;
      }
      this.leftObserver = this.createObserver(
        root,
        (entry) => (this.canScrollLeft = !entry.isIntersecting),
      );
      const firstElement = document.querySelector(`.facet${0}`);
      if (firstElement) {
        this.leftObserver.observe(firstElement);
      }

      this.rightObserver = this.createObserver(
        root,
        (entry) => (this.canScrollRight = !entry.isIntersecting),
      );

      const lastElement = document.querySelector(
        `.facet${this.productFacets.length - 1}`,
      );
      if (firstElement) {
        this.rightObserver.observe(lastElement);
      }
    },
    createObserver(root, cb) {
      return new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            cb(entry);
          });
        },
        {
          root,
          rootMargin: '0px',
          threshold: 1.0,
        },
      );
    },
    removeAllFacetsArrows() {
      this.leftObserver?.disconnect();
      this.leftObserver = null;
      this.rightObserver?.disconnect();
      this.rightObserver = null;
    },
    isBooleanFacet(facet) {
      return facet.type === 'Boolean';
    },
  },
  mounted() {
    this.initAllFacetsArrows();
    this.setSelectedFacetsAndSearchFromUrl();
  },
  beforeUnmount() {
    this.removeAllFacetsArrows();
  },
};
</script>

<template>
  <div class="embedded-facets__active-facet-list">
    <div
      class="embedded-facets__clear-facets-button"
      v-if="hasSelectedFacets"
      @click="clearSelectedFacets()"
    >
      <img class="embedded-facets__option-trash-icon" src="../../static/icons/trash_icon.svg" />
      {{ $globalTexts.category__facets__clearall }}
    </div>
    <div class="embedded-facets__active-facet" v-for="facet in activefacets" :key="facet.id">
      <div
        class="embedded-facets__active-facet-values"
        :class="{
          'embedded-facets__active-facet-values--multiple-selected':
            facet.values.length > 1,
        }"
      >
        <img
          class="embedded-facets__remove-facet"
          src="../../static/icons/cancel_black_icon.svg"
          v-if="facet.values.length > 1"
          @click="clearSelectedFacet(facet.id)"
        />
        <div v-for="facetValue in facet.values" :key="facetValue.id">
          <div class="embedded-facets__active-facet-value">
            {{ facetValue.text }}
            <img
              class="embedded-facets__remove-facet-value"
              src="../../static/icons/cancel_white_icon.svg"
              @click="clearSelectedFacetValue(facet.id, facetValue.id)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="embedded-facets__facet-spacer"></div>
  </div>
  <div class="embedded-facets__available-facet-list">
    <div class="embedded-facets__show-all-facet" @click="openFacetMenu()">
      <img class="embedded-facets__show-all-icon" src="../../static/icons/filter_icon.svg" />
      <div class="embedded-facets__show-all-text">{{ $globalTexts.category_facets_showall }}</div>
    </div>
    <div class="embedded-facets__available-facets allFacets" ref="allFacets">
      <div
        class="embedded-facets__available-facet-item allFacet"
        v-for="(facet, idx) in productFacets"
        :key="idx"
        :class="[
          'facet' + idx,
          facet.isDisabled
            ? 'embedded-facets__available-facet-item--disabled'
            : '',
        ]"
        @click="openSpecificFacetMenu(facet)"
      >
        <img
          class="embedded-facets__active-facet-icon"
          src="../../static/icons/active_facet_icon.svg"
          v-if="!isBooleanFacet(facet) && facet.isActive"
        />
        <checkbox
          v-if="isBooleanFacet(facet)"
          v-model="facet.isActive"
          :text="facet.name"
          @change="updateSelectedFacet(facet)"
        />
        <div>{{ facet.name }}</div>
      </div>
    </div>

    <div
      class="embedded-facets__available-facets-scroll-left"
      v-if="canScrollLeft"
      @click="scrollLeft('allFacets')"
    >
      <img
        class="embedded-facets__available-facets-scroll-left-icon"
        src="../../static/icons/scroll_arrow_left.svg"
      />
    </div>
    <div
      class="embedded-facets__available-facets-scroll-right"
      v-if="canScrollRight"
      @click="scrollRight('allFacets')"
    >
      <img
        class="embedded-facets__available-facets-scroll-right-icon"
        src="../../static/icons/scroll_arrow_right.svg"
      />
    </div>
    <div class="embedded-facets__facet-spacer"></div>
  </div>

  <teleport to="body">
    <all-facets
      v-if="showFacetMenu"
      :facet-list="productFacets"
      :open-specific-facet="openSpecificFacet"
      @update:selectedFacet="updateSelectedFacet"
      @update:clearSelectedFacets="clearSelectedFacets"
      @update:clearSelectedFacet="clearSelectedFacet"
      @close-menu="closeFacetMenu()"
    />
  </teleport>
</template>

<style>
.embedded-facets__active-facet-list {
  color: var(--color-basic-white);
  font-size: 13px;
  display: flex;
  overflow-x: auto;
  align-items: center;
  margin-top: 0.5rem;
}

.embedded-facets__clear-facets-button {
  padding: 0.5rem 0.4rem;
  margin: 0 0.4rem;
  color: var(--color-tasty-teal);
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.59px;
  white-space: nowrap;
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
}

.embedded-facets__option-trash-icon {
  height: 15px;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.embedded-facets__active-facet-values {
  display: flex;
  align-items: center;
}

.embedded-facets__active-facet-values--multiple-selected {
  border-radius: 30px;
  background: rgba(184, 204, 214, 0.25);
  margin: auto 5px;
}

.embedded-facets__active-facet-value {
  display: flex;
  padding: 10px 19px 10px 20px;
  margin: 5px;
  border-radius: 20px;
  white-space: nowrap;
  background: #333333;
  color: var(--color-neutrals-00);
  font-weight: 600;
  letter-spacing: 0.59px;
  justify-content: space-between;
  align-items: center;
  line-height: 15px;
}

.embedded-facets__remove-facet-value {
  height: 11px;
  margin-left: 20px;
  cursor: pointer;
}

.embedded-facets__remove-facet {
  height: 12px;
  margin: 0 5px 0 15px;
  cursor: pointer;
}

.embedded-facets__available-facet-list {
  display: flex;
  overflow-x: auto;
  font-size: 14px;
}

.embedded-facets__active-facet-list,
.embedded-facets__available-facet-list {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.embedded-facets__active-facet-list::-webkit-scrollbar,
.embedded-facets__available-facet-list::-webkit-scrollbar {
  display: none;
}

.embedded-facets__available-facets {
  display: flex;
}

.embedded-facets__show-all-facet {
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.3rem 0.5rem 0.8rem;
  color: var(--color-tasty-teal);
  border-radius: 20px;
  white-space: nowrap;
  box-shadow: 2.45714px 2.45714px 8px rgba(0, 10, 47, 0.12);
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.59px;
}

.embedded-facets__show-all-facet-icon {
  margin-right: 7px;
}

.embedded-facets__available-facet-item {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.3rem;
  border: 1px solid #6d6d6d;
  border-radius: 20px;
  white-space: nowrap;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.embedded-facets__available-facet-item--disabled {
  cursor: default;
  background-color: var(--color-grey550);
}

.embedded-facets__active-facet-icon {
  margin-right: 10px;
}

.embedded-facets__facet-spacer {
  min-width: 0.8rem;
  height: 1px;
}

.embedded-facets__available-facets-scroll-right {
  display: none;
}

@media (--tabletAndDesktop) {
  .embedded-facets__available-facet-list {
    margin-bottom: 1.5rem;

    position: relative;
    padding: 0.35rem 0.25rem;
  }

  .embedded-facets__available-facets {
    overflow: hidden;
  }

  .embedded-facets__available-facets-scroll-left {
    display: block;
    position: absolute;
    top: 4px;
    left: 8.5rem;
    height: 54px;
    width: 54px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: inset 0px 0px 3px rgba(0, 10, 47, 0.2);
  }

  .embedded-facets__available-facets-scroll-right {
    display: block;
    position: absolute;
    top: 4px;
    right: 0;
    height: 54px;
    width: 54px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    border-collapse: collapse;
    box-shadow: inset 0px 0px 3px rgba(0, 10, 47, 0.2);
  }

  .embedded-facets__available-facets-scroll-right-icon,
  .embedded-facets__available-facets-scroll-left-icon {
    position: absolute;
    top: 18px;
    width: 18px;
    height: 18px;
    left: 18px;
  }

  .embedded-facets__clear-facets-button {
    margin: 0 0.6rem;
  }

  .embedded-facets__show-all-facet {
    margin-left: 1rem;
    margin-right: 0.9rem;
  }
}
</style>
