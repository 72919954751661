<script>
let filterDebouncer = null;

export default {
  props: {
    facet: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:selected"],
  data() {
    return {
      filterQuery: "",
      filteredList: [],
    };
  },
  watch: {
    facet(facet) {
      this.filteredList = this.facetValues;
    },
  },
  computed: {
    facetValues() {
      return (
        (this.facet?.values || [])?.filter((v) => v.isActive || v.count > 0) ||
        []
      );
    },
  },
  methods: {
    isSelected(item) {
      return item.isActive;
    },
    toggleValue(item) {
      item.isActive = !item.isActive;
      const selectedItems = (this.filteredList || [])
        .filter((v) => v.isActive)
        .map((v) => v.id);
      this.$emit("update:selected", selectedItems);
    },
    getValueClasses(item) {
      const classes = [];
      if (!item.isActive && !item.count) {
        classes.push("checkbox-facet__list-item--disabled");
      } else if (item.isActive) {
        classes.push("checkbox-facet__list-item--active");
      }
      return classes;
    },
    filterList() {
      if (filterDebouncer) {
        clearTimeout(filterDebouncer);
      }

      filterDebouncer = setTimeout(() => {
        this.filteredList = this.facetValues.filter((i) =>
          i.text.toLowerCase().includes(this.filterQuery.toLowerCase())
        );
      }, 200);
    },
  },
  mounted() {
    this.filteredList = this.facetValues;
  },
};
</script>

<template>
  <div class="checkbox-facet__filter">
    <input
      type="text"
      class="checkbox-facet__filter-input"
      :placeholder="$globalTexts.category__facets__filter"
      v-model="filterQuery"
      @keyup="filterList()"
    />
    <img
      class="checkbox-facet__filter-icon"
      src="@/static/icons/search_icon.svg"
    />
  </div>
  <ul class="checkbox-facet__list">
    <li
      v-for="item in filteredList"
      :key="item.text"
      class="checkbox-facet__list-item"
      :class="getValueClasses(item)"
      @click="toggleValue(item)"
    >
      <img
        v-if="isSelected(item)"
        class="checkbox-facet__facet-list-checkmark"
        src="../../static/icons/checkmark_icon.svg"
      />
      <span>{{ item.text }}</span>
      <span class="checkbox-facet__list-item-count">{{ item.count }}</span>
    </li>
  </ul>
</template>

<style>
.checkbox-facet__filter {
  position: relative;
  margin: 10px 0.5rem 10px;
}

.checkbox-facet__filter-input {
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 0.75rem;
  font-size: 14px;
  border-radius: 5px;
  border-width: 1px;
  border-color: black;
  outline: none;
}

.checkbox-facet__filter-input::placeholder {
  color: black;
}

.checkbox-facet__filter-icon {
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  height: 18px;
  transform: translateY(-50%);
}

.checkbox-facet__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.checkbox-facet__list .checkbox-facet__list-item:first-child {
  border-top: 1px solid var(--color-border-50);
}

.checkbox-facet__list-item {
  display: flex;
  padding: 16px 1.1em 15px;
  cursor: pointer;
  border-bottom: 1px solid var(--color-border-50);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1em;
  color: #000000;
  position: relative;
  overflow: hidden;
}

.checkbox-facet__list-item--active {
  font-weight: 600;
}

.checkbox-facet__list-item--disabled {
  opacity: 0.5;
}
.checkbox-facet__list-item--disabled:hover {
  background-color: var(--color-dark-grey);
  cursor: default;
}

.checkbox-facet__facet-list-checkmark {
  margin-right: 0.5rem;
}

.checkbox-facet__list-item-count {
  margin-left: auto;
}
</style>
